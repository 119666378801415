@charset "utf-8";

// base variables
$base-font-family: "";
$base-font-size: 1.2em;
$text-color: #191919;
$link-color: #17a2b8;

// Width of the content area
$content-width:    1800px;
$on-palm:          576px;
$on-laptop:        768px;
$on-desktop:       992px;
$on-jumbo:        1200px;
// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

/* buttons to theme */
 

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    
  ),
  $theme-colors
);

@import "base", "theme-colors", "pages", "custom";
